<!-- eslint-disable vue/no-v-html -->
<template>
<div v-if="isRowHeader" class="table-header sort">
    {{ columnTitle }}
</div>
<div
    v-else
    class="table-cell"
    v-html="cellValue"
/>
</template>

<script lang="ts">

import utilMixins from '../../store/mixins/utilMixins';

export default {
    name: 'EmailHotColumn',
    mixins: [utilMixins],
    data() {
        return {
            hotInstance: null,
            TD: null,
            row: null,
            col: null,
            prop: null,
            value: null,
            cellProperties: null,
        };
    },
    computed: {
        rows() {
            return this.$store.state.database.studentDataView.rows;
        },
        record() {
            const { row, col } = this;
            return this.rows[row][col];
        },
        isRowHeader() {
            return this.row === 0;
        },
        cellValue() {
            const { record } = this;
            if (!record) return '';
            return this.$_utilMixins_format_email(record.cellValue || '');
        },
        column() {
            const { record } = this;
            if (!record) return '';
            return record.column;
        },
        columnName() {
            const { column } = this;
            if (!column) return '';
            return column.name;
        },
        columnTitle() {
            const { column } = this;
            if (!column) return '';
            return column.title;
        },
    },
};
</script>
<style scoped>

</style>
