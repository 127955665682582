<!-- eslint-disable max-len -->
<template>
<div>
    <div
        v-if="hotSettings.data.length > 0"
        :key="`key_${key}`"
        class="sd-hot-table-container"
    >
        <HotTable
            ref="sdHotTable"
            :key="`table_${key}`"
            :settings="hotSettings"
        >
            <HotColumn
                v-for="column in columns"
                :key="column.name + key"
                :width="column.width"
            >
                <component
                    :is="column.componentName"
                    hot-renderer
                />
            </HotColumn>
        </HotTable>
    </div>
    <div class="kt-pagination kt-pagination--brand pt-3">
        <ul class="kt-pagination__links">
            <li class="kt-pagination__link--first">
                <a href="#"><i class="fa fa-angle-double-left kt-font-brand" /></a>
            </li>
            <li class="kt-pagination__link--next">
                <a href="#"><i class="fa fa-angle-left kt-font-brand" /></a>
            </li>
            <li>
                <a href="#">...</a>
            </li>
            <li>
                <a href="#">29</a>
            </li>
            <li>
                <a href="#">30</a>
            </li>
            <li>
                <a href="#">31</a>
            </li>
            <li class="kt-pagination__link--active">
                <a href="#">32</a>
            </li>
            <li>
                <a href="#">33</a>
            </li>
            <li>
                <a href="#">34</a>
            </li>
            <li>
                <a href="#">...</a>
            </li>
            <li class="kt-pagination__link--prev">
                <a href="#"><i class="fa fa-angle-right kt-font-brand" /></a>
            </li>
            <li class="kt-pagination__link--last">
                <a href="#"><i class="fa fa-angle-double-right kt-font-brand" /></a>
            </li>
        </ul>
        <div class="kt-pagination__toolbar">
            <select class="form-control kt-font-brand" style="width: 60px;">
                <option value="10">
                    10
                </option>
                <option value="20">
                    20
                </option>
                <option value="30">
                    30
                </option>
                <option value="50">
                    50
                </option>
                <option value="100">
                    100
                </option>
            </select>
            <span class="pagination__desc">
                Displaying 10 of 230 records
            </span>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import hotTableMixins from '../store/mixins/hotTableMixins';
import studentMixins from '../store/mixins/studentMixins';
import panelMixins from '../store/mixins/panelMixins';
import { hotDefaults } from '../store/hotDefaults';

import StudentHotColumn from './StudentDataView/StudentHotColumn.vue';
import TextHotColumn from './StudentDataView/TextHotColumn.vue';
import EmailHotColumn from './StudentDataView/EmailHotColumn.vue';

// email, text, achievementLevel, badge
// colored background? inline span badge? boolean? icon?

export default Vue.extend({
    name: 'BiliteracySealHotTable',
    components: {
        StudentHotColumn,
        TextHotColumn,
        EmailHotColumn,
    },
    mixins: [
        hotTableMixins,
        studentMixins,
        panelMixins,
    ],
    data() {
        const data = {
            key: 1,
            debounced: null,
            hotSettings: {
                // autoColumnSize: false,
                autoRowSize: false,
                columnHeaderHeight: 50,
                comments: false,
                disableVisualSelection: true,
                fragmentSelection: true,
                editor: false,
                readOnly: true,
                // contextMenu: ['commentsAddEdit', 'commentsRemove'],
                data: [],
                fillHandle: {
                    autoInsertRow: false,
                    autoInsertCol: false,
                },
                fixedColumnsStart: 1,
                fixedRowsTop: 1,
                // rowHeaderWidth: 50,
                rowHeights: 50,
                // manualColumnResize: true,
                colHeaders: false,
                rowHeaders: false,
                // cell: comments,
            },
        };

        data.hotSettings = {
            ...hotDefaults,
            ...data.hotSettings,
        };

        return data;
    },
    computed: {
        deviceType() {
            return this.$store.state.deviceType;
        },
        user() {
            return this.$store.state.user;
        },
        columns() {
            return this.$store.state.database.studentDataView.columns
                .filter((c) => c.enabled && !['achievementLevel', 'badgeTitle', 'googleEmail'].includes(c.name));
        },
        rows() {
            const rows = [...this.$store.state.database.studentDataView.rows];
            if (rows.length === 0 || rows.length === 1) return []; // header row

            // if (currentPage < 1 || currentPage > Math.ceil(rows.length / perPage)) {
            //     currentPage = 1;
            // }
            // const [header, ...data] = rows;
            // const output = data.slice(
            //     (currentPage - 1) * perPage,
            //     currentPage * perPage,
            // );
            // output.unshift(header);
            return rows;
        },
        currentPage() {
            return this.$store.state.database.studentDataView.currentPage;
        },
        perPage() {
            return this.$store.state.database.studentDataView.perPage;
        },
    },
    watch: {
        // currentPage() {
        //     this.populate();
        // },
        // perPage() {
        //     this.populate();
        // },
    },
    mounted() {
        this.populate();
    },
    methods: {
        populate() {
            this.hotSettings.data = this.rows;
            if (this.deviceType == 'tablet' || this.deviceType == 'mobile') {
                this.hotSettings.height = document.documentElement.clientHeight - 130;
            } else {
                this.hotSettings.height = document.documentElement.clientHeight - 160;
            }
        },
    },
});

</script>

<style scoped src="../css/colors.scss" lang="scss" />

<style lang="scss">

.sd-hot-table-container {
    width: 100%;
    overflow-x: hidden;
    max-height: 500px;
}

.sd-hot-table-container .handsontable td, .handsontable tr, .handsontable th {
    border-top: 0 !important;
    /* border-bottom: 0 !important; */
    border-bottom-color:  #ebedf2;
    border-left: 0 !important;
    border-right: 0 !important;
    margin-top: 4px;
    margin-bottom: 4px;
}

.sd-hot-table-container .table-header {
    text-transform: uppercase;
    border-bottom-color: inherit;
    width: auto;
    color: #B5B5C3 !important;
    white-space: nowrap;
    padding-top: 14px;
}
.sd-hot-table-container .sort:hover {
    cursor: pointer;
    color: #007aff !important;
}

.sd-hot-table-container td {
    padding-left: 0;
    padding-right: 0;
    max-height: 50px;
}

.sd-hot-table-container td > div.table-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space-collapse: collapse;
    height: 49px;
    max-height: 49px;
    width: 100%;
    display: grid;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 8px;
    line-height: 1.3rem;
}

.sd-hot-table-container .table-header.sort:before {
    display: inline-block;
    font: normal normal normal 10px/1 "LineAwesome";
    content: "\f11f\f122"; /* la-arrow-down amd up */
    letter-spacing: -4px;
    margin-left: 4px;
    margin-right: 4px;
}

.sd-hot-table-container .table-header.sort.sort-down:before {
    content: "\f11f"; /* la-arrow-down */
    color: #007bff;
}

.sd-hot-table-container .table-header.sort.sort-up:before {
    content: "\f122"; /* la-arrow-up */
    color: #007bff;
}

</style>
