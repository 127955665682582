var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.hotSettings.data.length > 0
      ? _c(
          "div",
          { key: `key_${_vm.key}`, staticClass: "sd-hot-table-container" },
          [
            _c(
              "HotTable",
              {
                key: `table_${_vm.key}`,
                ref: "sdHotTable",
                attrs: { settings: _vm.hotSettings },
              },
              _vm._l(_vm.columns, function (column) {
                return _c(
                  "HotColumn",
                  {
                    key: column.name + _vm.key,
                    attrs: { width: column.width },
                  },
                  [
                    _c(column.componentName, {
                      tag: "component",
                      attrs: { "hot-renderer": "" },
                    }),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "kt-pagination kt-pagination--brand pt-3" },
      [
        _c("ul", { staticClass: "kt-pagination__links" }, [
          _c("li", { staticClass: "kt-pagination__link--first" }, [
            _c("a", { attrs: { href: "#" } }, [
              _c("i", { staticClass: "fa fa-angle-double-left kt-font-brand" }),
            ]),
          ]),
          _c("li", { staticClass: "kt-pagination__link--next" }, [
            _c("a", { attrs: { href: "#" } }, [
              _c("i", { staticClass: "fa fa-angle-left kt-font-brand" }),
            ]),
          ]),
          _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v("...")])]),
          _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v("29")])]),
          _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v("30")])]),
          _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v("31")])]),
          _c("li", { staticClass: "kt-pagination__link--active" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("32")]),
          ]),
          _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v("33")])]),
          _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v("34")])]),
          _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v("...")])]),
          _c("li", { staticClass: "kt-pagination__link--prev" }, [
            _c("a", { attrs: { href: "#" } }, [
              _c("i", { staticClass: "fa fa-angle-right kt-font-brand" }),
            ]),
          ]),
          _c("li", { staticClass: "kt-pagination__link--last" }, [
            _c("a", { attrs: { href: "#" } }, [
              _c("i", {
                staticClass: "fa fa-angle-double-right kt-font-brand",
              }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "kt-pagination__toolbar" }, [
          _c(
            "select",
            {
              staticClass: "form-control kt-font-brand",
              staticStyle: { width: "60px" },
            },
            [
              _c("option", { attrs: { value: "10" } }, [_vm._v(" 10 ")]),
              _c("option", { attrs: { value: "20" } }, [_vm._v(" 20 ")]),
              _c("option", { attrs: { value: "30" } }, [_vm._v(" 30 ")]),
              _c("option", { attrs: { value: "50" } }, [_vm._v(" 50 ")]),
              _c("option", { attrs: { value: "100" } }, [_vm._v(" 100 ")]),
            ]
          ),
          _c("span", { staticClass: "pagination__desc" }, [
            _vm._v(" Displaying 10 of 230 records "),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }