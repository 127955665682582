<template>
<div v-if="isRowHeader" class="table-header sort">
    {{ columnTitle }}
</div>
<div
    v-else
    :style="{
        backgroundColor,
        fontWeight,
        textAlign,
    }"
    :class="[
        `table-cell font`,
        `${color}`,
    ]"
>
    {{ cellValue }}
</div>
</template>

<script lang="ts">
// @ts-ignore
import cssVars from '../../css/colors.scss';

export default {
    name: 'TextHotColumn',
    data() {
        return {
            hotInstance: null,
            TD: null,
            row: null,
            col: null,
            prop: null,
            value: null,
            cellProperties: null,
            key: 0,
        };
    },
    computed: {
        rows() {
            return this.$store.state.database.studentDataView.rows;
        },
        record() {
            const { row, col } = this;
            return this.rows[row][col];
        },
        isRowHeader() {
            return this.row === 0;
        },
        cellValue() {
            const { record } = this;
            if (!record) return '';
            return record.cellValue || '';
        },
        backgroundColor() {
            const { color } = this;
            if (!color) return 'none';
            const hex = cssVars[color || 'Grey'];
            const { r, g, b } = hexToRgb(hex);
            return `rgba(${r},${g},${b},.1)`;
        },
        color() {
            const { record } = this;
            if (!record) return null;
            const { color } = record;
            if (!color) return '';
            return color;
        },
        column() {
            const { record } = this;
            if (!record) return '';
            return record.column;
        },
        columnProps() {
            const { column } = this;
            if (!column) return {};
            return column.props ? column.props : {};
        },
        columnName() {
            const { column } = this;
            if (!column) return '';
            return column.name;
        },
        columnTitle() {
            const { column } = this;
            if (!column) return '';
            return column.title;
        },
        fontWeight() {
            const { columnProps } = this;
            if (!columnProps) return false;
            return columnProps.fontWeight || 'normal';
        },
        textAlign() {
            const { columnProps } = this;
            if (!columnProps) return false;
            return columnProps.align || 'center';
        },
    },
    watch: {
    },
};

function hexToRgb(h) {
    // https://stackoverflow.com/a/5624139
    let hex = h;
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
    } : null;
}

</script>
<style scoped>

</style>
