var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isRowHeader
    ? _c("div", { staticClass: "table-header sort" }, [
        _vm._v(" " + _vm._s(_vm.columnTitle) + " "),
      ])
    : _c("div", {
        staticClass: "table-cell",
        domProps: { innerHTML: _vm._s(_vm.cellValue) },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }